import React from "react"
import SEO from "../components/seo/seo"
import { Header, Text } from "components"

const NotFoundPage = () => {
  const block = "maars-404"
  return (
    <div className={block}>
      <SEO title="404: Not found" />
      <Header
        size="h1"
        headerStyle="h1"
        align="center"
        color="black"
        marginBottom="small"
        text="404"
      />
      <Text
        size="large"
        color="black"
        align="center"
        text="Page Not Found 🍔"
      />
    </div>
  )
}

export default NotFoundPage
